import { Box, Select, FormControl, Typography, FormHelperText, withStyles } from '@material-ui/core';
import React from 'react';

const styles = (theme) => ({
  root: {
    '& .MuiFilledInput-input': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  label: {
    fontSize: 13,
    fontWeight: 600,
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

function CustomSelect({ label, classes: c, error, helperText, children, ...rest }) {
  return (
    <Box align="left" className={c.root}>
      <FormControl className={c.formControl} error={error}>
        {label && (
          <Typography variant="subtitle1" className={c.label}>
            {label}
          </Typography>
        )}

        <Select {...rest} label="">
          {children}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
}

export default withStyles(styles, { withTheme: true })(CustomSelect);
