const styles = (theme) => ({
  root: {
    width: 910,
    maxWidth: '97vw',
    margin: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(8),
    },
  },
  rootCompact: {
    width: 640,
  },
  logo: {
    width: 147,
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3, 8),
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 3),
    },
  },
  testimonials: {
    marginTop: theme.spacing(6),
  },
  cardHeader: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    fontWeight: 'bold',
  },
  subHeader1: {
    fontWeight: 'bold',
  },
  divider: {
    height: 'auto',
    margin: theme.spacing(0, 2),
    opacity: 0.5,
  },
  needHelp: {
    color: '#1EB2BB',
    fontWeight: 'bold',
  },
  chatWithSupport: {
    backgroundColor: '#1EB2BB',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#0EA2AB',
    },
  },
  intercomFloatingPanel: {
    marginRight: theme.spacing(2),
  },
  chatWithSupportFab: {
    height: 80,
    lineHeight: 1,
    width: 80,
    marginTop: theme.spacing(2),
  },
});

export default styles;
