// @flow

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  Typography,
  MenuItem,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { InjectedProps, injectStripe } from 'react-stripe-elements';
import validate from 'validate.js';
import validator from 'validator';

import environment from '../../config/environment';
import { PadlockIcon } from '../../icons';
import constraints from '../../services/validation/constraints';
import CouponApplied from '../CouponApplied';
import FeedbackSnackbarContent from '../FeedbackSnackbarContent';
import StripeCardsSection from '../StripeCardsSection';
import TextField from '../TextField';
import Select from '../Select';
import COUNTRIES from '../../constants/country';
import styles from './styles';

type Props = InjectedProps & {
  onComplete: Function,
  planSlug: string,
  plan: Object,
  prePurchased: boolean,
  sponsoring: boolean,
};

type State = {
  emailAddress: string,
  fullName: string,
  password: string,
  passwordConfirmation: string,
  couponCode: string,
  couponDetails: Object,
  marketingOptIn: boolean,
  billingAddress: Object,

  performingAction: boolean,
  errors: Object,
  serverSuccess?: boolean,
  serverError: string,
  showErrors: boolean,

  alreadyRegistered?: boolean,
  alreadyRegisteredChecking?: boolean,
  resetPasswordUrl?: string,
};

class CheckoutForm extends Component<Props, State> {
  constructor() {
    super();

    this.state = {
      emailAddress: '',
      emailAddressConfirmation: '',
      emailAddressChanged: false,
      fullName: '',
      password: '',
      passwordConfirmation: '',
      billingAddress: {
        country: '',
      },
      couponCode: '',
      couponDetails: null,
      marketingOptIn: false,

      foundSubscription: false, // pre-purchased

      performingAction: false,
      errors: null,
      serverError: '',
      showErrors: false,
    };
  }

  handleApplyCouponClick = async () => {
    const { couponCode, couponDetails } = this.state;

    if (!couponCode || (couponDetails && couponCode === couponDetails.id)) {
      return;
    }

    this.setState(
      {
        performingAction: true,
        couponDetails: null,
        serverError: '',
      },
      async () => {
        const response = await fetch(`${environment.REACT_APP_BACKEND_URL}/coupon/${encodeURIComponent(couponCode)}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        });

        if (response.ok) {
          const content = await response.json();
          this.setState({ performingAction: false, couponDetails: content });
        } else {
          await this.handleError(response, 'Coupon Error');
        }
      },
    );
  };

  validateEmailFormFunc = () => {
    const errors = this.validateEmailForm();

    if (errors) {
      this.setState({
        errors,
        showErrors: true,
      });
    } else {
      this.setState({
        errors: null,
        showErrors: false,
      });
    }
  };

  handleFindSubscription = () => {
    const errors = this.validateEmailForm();

    if (errors) {
      this.setState({
        errors,
        showErrors: true,
      });
    } else {
      const { emailAddress, alreadyRegisteredChecking } = this.state;

      if (alreadyRegisteredChecking || !emailAddress || !validator.isEmail(emailAddress)) {
        return;
      }

      this.setState(
        {
          performingAction: true,
          errors: null,
          serverError: '',
          showErrors: true,
        },
        async () => {
          const { planSlug } = this.props;
          this.setState({ alreadyRegistered: undefined });

          const response = await fetch(
            `${environment.REACT_APP_BACKEND_URL}/stripe-registered?email=${encodeURIComponent(emailAddress)}`,
          );

          if (response.ok) {
            const content = await response.json();
            if (emailAddress === content.email) {
              if (content.registered) {
                const resetPasswordUrl = this.generateResetPasswordUrl(content.email);
                this.setState({
                  alreadyRegistered: true,
                  resetPasswordUrl,
                  alreadyRegisteredChecking: false,
                  performingAction: false,
                });
              } else if (!content.subscribed) {
                this.setState({
                  alreadyRegistered: false,
                  resetPasswordUrl: '',
                  alreadyRegisteredChecking: false,
                  foundSubscription: false,
                  performingAction: false,
                  serverError: 'subscription not found',
                });
              } else if (content.subscribed) {
                this.setState({
                  alreadyRegistered: false,
                  resetPasswordUrl: '',
                  alreadyRegisteredChecking: false,
                  foundSubscription: true,
                  performingAction: false,
                });
              }
            }
          } else {
            await this.handleError(response, 'Signup Error');
          }
        },
      );
    }
  };

  handleRegisterClick = () => {
    const errors = this.validateForm();

    if (errors) {
      this.setState({
        errors,
        showErrors: true,
      });
    } else {
      this.setState(
        {
          performingAction: true,
          errors: null,
          serverError: '',
          showErrors: true,
        },
        async () => {
          const { prePurchased, sponsoring, planSlug, stripe, onComplete } = this.props;
          const { fullName, emailAddress, password, couponCode, billingAddress, marketingOptIn } = this.state;
          const { token } = prePurchased || sponsoring ? {} : await stripe.createToken({ name: fullName });

          if (!prePurchased && !sponsoring && !token) {
            this.setState({ performingAction: false });
            return;
          }

          const metadata = {
            stripeToken: !prePurchased && !sponsoring ? token.id : null,
            name: fullName,
            email: emailAddress,
            password,
            couponCode: !prePurchased && !sponsoring ? couponCode : null,
            marketingOptIn,
            plan: planSlug,
            billingAddress,
            prePurchased,
          };

          // add additional tags when sponsoring
          // sponsored, stripe_payment_id
          if (sponsoring) {
            metadata.sponsored = true;
            metadata.stripePaymentId = planSlug;
          }

          const response = await fetch(`${environment.REACT_APP_BACKEND_URL}/signup`, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(metadata),
          });
          if (response.ok) {
            const content = await response.json();
            console.log('Signup Succeed', content);

            if (content.isNewCustomer) {
              if (onComplete && !sponsoring) {
                onComplete();
              } else if (sponsoring) {
                this.setState({ serverSuccess: true });
              }
            } else {
              const resetPasswordUrl = this.generateResetPasswordUrl(content.vhxCustomer.email);
              this.setState({ serverSuccess: true, alreadyRegistered: true, resetPasswordUrl });
            }
          } else {
            await this.handleError(response, 'Signup Error');
          }
        },
      );
    }
  };

  generateResetPasswordUrl(email) {
    return `${environment.VHX_PORTAL_URL}/forgot_password?email=${encodeURIComponent(email)}`;
  }

  handleFieldChange = (fieldName) => (event) => {
    const fields = fieldName.split('.');
    const { showErrors } = this.state;
    const { value } = event.target;

    if (fields.length === 1) {
      this.setState({ [fieldName]: value }, () => {
        if (showErrors) {
          this.validateField(fieldName, value);
        }
      });
    } else if (fields.length === 2) {
      this.setState(
        {
          [fields[0]]: {
            ...this.state[fields[0]],
            [fields[1]]: value,
          },
        },
        () => {
          if (showErrors) {
            this.validateField(fields[1], value);
          }
        },
      );
    }
  };

  handleEmailFieldChange = (event) => {
    this.handleFieldChange('emailAddress')(event);
    this.setState({ alreadyRegistered: undefined, alreadyRegisteredChecking: true });
  };

  handleCouponFieldKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.handleApplyCouponClick();
    } else if (event.key === 'Escape') {
      this.setState({ couponCode: '' });
    }
  };

  handleFieldCheck = (fieldName) => (event) => {
    const { showErrors } = this.state;
    const value = event.target.checked;
    this.setState({ [fieldName]: value }, () => {
      if (showErrors) {
        this.validateField(fieldName, value);
      }
    });
  };

  handleEmailFieldBlur = () => {
    const { planSlug, sponsoring } = this.props;
    const { emailAddress, alreadyRegisteredChecking } = this.state;
    if (sponsoring || !alreadyRegisteredChecking || !emailAddress || !validator.isEmail(emailAddress)) {
      return;
    }
    this.setState({ alreadyRegistered: undefined });
    fetch(`${environment.REACT_APP_BACKEND_URL}/stripe-registered?email=${encodeURIComponent(emailAddress)}`).then(
      async (response) => {
        if (response.ok) {
          const content = await response.json();
          if (this.state.emailAddress === content.email) {
            if (content.registered) {
              const resetPasswordUrl = this.generateResetPasswordUrl(content.email);
              this.setState({ alreadyRegistered: true, resetPasswordUrl, alreadyRegisteredChecking: false });
            } else {
              this.setState({ alreadyRegistered: false, resetPasswordUrl: '', alreadyRegisteredChecking: false });
            }
          }
        }
      },
    );
  };

  validateEmailForm = () => {
    const { emailAddress, emailAddressConfirmation } = this.state;

    let attributes = {
      emailAddress,
      emailAddressConfirmation,
    };
    let consts = {
      emailAddress: constraints.emailAddress,
      emailAddressConfirmation: constraints.emailAddressConfirmation,
    };

    const errors = validate(attributes, consts);

    return errors;
  };

  validateForm = () => {
    const { prePurchased, sponsoring } = this.props;
    const {
      fullName,
      emailAddress,
      emailAddressConfirmation,
      password,
      passwordConfirmation,
      billingAddress,
    } = this.state;

    let attributes = {
      fullName,
      emailAddress,
      password,
      passwordConfirmation,
    };
    let consts = {
      fullName: constraints.fullName,
      emailAddress: constraints.emailAddress,
      password: constraints.password,
      passwordConfirmation: constraints.passwordConfirmation,
    };

    if (!prePurchased && !sponsoring) {
      attributes = {
        ...attributes,
        ...billingAddress,
      };
      consts = {
        ...consts,
        address: constraints.billingAddress.address,
        city: constraints.billingAddress.city,
        state: constraints.billingAddress.state,
        zip: constraints.billingAddress.zip,
        country: constraints.billingAddress.country,
      };
    }
    if (sponsoring) {
      attributes.emailAddressConfirmation = emailAddressConfirmation;
      consts.emailAddressConfirmation = constraints.emailAddressConfirmation;
    }

    const errors = validate(attributes, consts);

    return errors;
  };

  validateField = (fieldName, fieldValue) => {
    switch (fieldName) {
      case 'password':
      case 'passwordConfirmation': {
        const { errors, password, passwordConfirmation } = this.state;
        const newErrors =
          validate(
            { password, passwordConfirmation },
            {
              password: constraints.password,
              passwordConfirmation: constraints.passwordConfirmation,
            },
          ) || {};
        this.setState({
          errors: {
            ...errors,
            password: newErrors.password,
            passwordConfirmation: newErrors.passwordConfirmation,
          },
        });
        return;
      }
      default: {
        const { errors } = this.state;
        const newErrors = validate({ [fieldName]: fieldValue }, { [fieldName]: constraints[fieldName] }) || {};
        this.setState({ errors: { ...errors, [fieldName]: newErrors[fieldName] } });
      }
    }
  };

  handleFeedbackClose = () => {
    this.setState({ serverError: '' });
  };

  async handleError(response, tag) {
    let errorMessage;
    let error;
    let innerError;
    try {
      const content = await response.json();
      errorMessage = (content && content.message) || response.statusText;
      error = this.formatError(errorMessage);
    } catch (error_) {
      innerError = error_;
      errorMessage = 'Server Error. Please try again later.';
      error = errorMessage;
    }
    console.error(tag || '', errorMessage, innerError || '');
    this.setState({ performingAction: false, serverError: error });
  }

  formatError = (error: string): string => {
    const c = this.props.classes;
    error = this.formatLinks(error, { class: c.errorLink, target: '_blank' });
    return error;
  };

  formatLinks = (message: string, props): any => {
    if (!message) {
      return message;
    }
    const parts = String(message).split(/\b([^\s]+?@[^\s]+)\b/gi);
    const result = parts.map((part) => {
      if (validator.isEmail(part)) {
        return (
          <Link href={`mailto:${part}`} {...props}>
            {part}
          </Link>
        );
      }
      return part;
    });
    return result;
  };

  render() {
    const { classes: c, plan, prePurchased, sponsoring } = this.props;

    const {
      performingAction,

      fullName,
      emailAddress,
      emailAddressConfirmation,
      password,
      passwordConfirmation,
      couponCode,
      couponDetails,
      marketingOptIn,
      alreadyRegistered,
      resetPasswordUrl,
      billingAddress,

      foundSubscription,
      errors,
      serverSuccess,
      serverError,
      showErrors,
    } = this.state;

    const prePurchasedStep1 = prePurchased && !foundSubscription;
    const prePurchasedStep2 = prePurchased && foundSubscription;

    return (
      <form className={c.root} onSubmit={(e) => e.preventDefault()}>
        {!prePurchased && !sponsoring ? (
          <Typography align="left" variant="h6" color="primary" className={c.formHeader}>
            BrilliantTV Account Information
          </Typography>
        ) : (
          <>
            {foundSubscription && (
              <Box my={2}>
                <FeedbackSnackbarContent
                  className={c.feedback}
                  variant="success"
                  message={<span>We found your Brilliant TV subscription</span>}
                />
              </Box>
            )}
            <Typography align="left" variant="h6" color="primary" className={c.formHeader}>
              Create your Brilliant TV Account
            </Typography>
          </>
        )}

        {!serverSuccess && (
          <Grid item container direction="column" className={c.grid}>
            {(!prePurchased || prePurchasedStep1 || prePurchasedStep2) && (
              <Grid item className={c.grid}>
                <TextField
                  className={c.textField}
                  disabled={performingAction}
                  error={!!(errors && errors.emailAddress)}
                  fullWidth
                  helperText={errors && errors.emailAddress ? errors.emailAddress[0] : ''}
                  label="Your Email Address"
                  onChange={this.handleEmailFieldChange}
                  onBlur={this.handleEmailFieldBlur}
                  placeholder="Your Email Address Here"
                  required
                  type="email"
                  value={emailAddress}
                  variant="filled"
                />
                {!serverSuccess && !performingAction && alreadyRegistered && (
                  <>
                    <FormHelperText error>
                      {/* It looks like you already have been registered at BrilliantTV. */}
                      It looks like you already have an account with BrilliantTV.
                    </FormHelperText>

                    {resetPasswordUrl && (
                      <FormHelperText>
                        Would you like to{' '}
                        <Link href={resetPasswordUrl} className={c.resetPasswordLink} target="_blank">
                          reset your password
                        </Link>
                        ?
                      </FormHelperText>
                    )}
                  </>
                )}
              </Grid>
            )}
            {(prePurchasedStep1 || sponsoring || prePurchasedStep2) && (
              <Grid item className={c.grid}>
                <TextField
                  className={c.textField}
                  disabled={performingAction}
                  error={!!(errors && errors.emailAddressConfirmation)}
                  fullWidth
                  helperText={errors && errors.emailAddressConfirmation ? errors.emailAddressConfirmation[0] : ''}
                  label="Confirm Your Email Address"
                  onChange={this.handleFieldChange('emailAddressConfirmation')}
                  onBlur={this.validateEmailFormFunc}
                  placeholder="Your Email Address Here"
                  required
                  type="email"
                  value={emailAddressConfirmation}
                  variant="filled"
                />
              </Grid>
            )}
            {(!prePurchased || prePurchasedStep2) && (
              <Grid item className={c.grid}>
                <TextField
                  className={c.textField}
                  disabled={performingAction}
                  error={!!(errors && errors.fullName)}
                  fullWidth
                  helperText={errors && errors.fullName ? errors.fullName[0] : ''}
                  label="Your Full Name"
                  onChange={this.handleFieldChange('fullName')}
                  placeholder="Your Name Here"
                  required
                  type="text"
                  value={fullName}
                  variant="filled"
                />
              </Grid>
            )}
            {(!prePurchased || prePurchasedStep2) && (
              <>
                <Grid item className={c.grid}>
                  <TextField
                    autoComplete="new-password"
                    className={c.textField}
                    disabled={performingAction}
                    error={!!(errors && errors.password)}
                    fullWidth
                    helperText={errors && errors.password ? errors.password[0] : ''}
                    label="Set your BrilliantTV password"
                    onChange={this.handleFieldChange('password')}
                    placeholder="Your Password"
                    required
                    type="password"
                    value={password}
                    variant="filled"
                  />
                </Grid>
                <Grid item className={c.grid}>
                  <TextField
                    autoComplete="new-password"
                    className={c.textField}
                    disabled={performingAction}
                    error={!!(errors && errors.passwordConfirmation)}
                    fullWidth
                    helperText={errors && errors.passwordConfirmation ? errors.passwordConfirmation[0] : ''}
                    label="Confirm your password"
                    onChange={this.handleFieldChange('passwordConfirmation')}
                    placeholder="Repeat Password"
                    required
                    type="password"
                    value={passwordConfirmation}
                    variant="filled"
                  />
                </Grid>
              </>
            )}
          </Grid>
        )}
        {!prePurchased && !sponsoring && (
          <>
            <Typography variant="h6" color="primary" className={c.formHeader}>
              Billing Address Information
            </Typography>
            <Grid item container spacing={2} direction="row" xs-direction="column" className={c.grid}>
              <Grid item xs={12}>
                <TextField
                  className={c.textField}
                  disabled={performingAction}
                  error={!!(errors && errors.address)}
                  fullWidth
                  helperText={errors && errors.address ? errors.address[0] : ''}
                  label="Address"
                  onChange={this.handleFieldChange('billingAddress.address')}
                  placeholder="Address"
                  required
                  type="text"
                  value={billingAddress.address}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  className={c.textField}
                  disabled={performingAction}
                  error={!!(errors && errors.city)}
                  fullWidth
                  helperText={errors && errors.city ? errors.city[0] : ''}
                  label="City"
                  onChange={this.handleFieldChange('billingAddress.city')}
                  placeholder="City"
                  required
                  type="text"
                  value={billingAddress.city}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  className={c.textField}
                  disabled={performingAction}
                  error={!!(errors && errors.state)}
                  fullWidth
                  helperText={errors && errors.state ? errors.state[0] : ''}
                  label="State"
                  onChange={this.handleFieldChange('billingAddress.state')}
                  placeholder="State"
                  required
                  type="text"
                  value={billingAddress.state}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  className={c.textField}
                  disabled={performingAction}
                  error={!!(errors && errors.zip)}
                  fullWidth
                  helperText={errors && errors.zip ? errors.zip[0] : ''}
                  label="Zip"
                  onChange={this.handleFieldChange('billingAddress.zip')}
                  placeholder="Zip"
                  required
                  type="text"
                  value={billingAddress.zip}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Select
                  error={!!(errors && errors.country)}
                  fullWidth
                  helperText={errors && errors.country ? errors.country[0] : ''}
                  label="Country"
                  value={billingAddress.country}
                  variant="filled"
                  onChange={this.handleFieldChange('billingAddress.country')}
                >
                  <MenuItem value="">
                    <em>Country</em>
                  </MenuItem>
                  {COUNTRIES.map(({ value, text }) => (
                    <MenuItem key={value} value={value}>
                      {text}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>

            <Typography variant="h6" color="primary" className={c.formHeader}>
              Payment Information
            </Typography>

            <Grid item container direction="column" className={c.grid}>
              <StripeCardsSection showError={showErrors} />
            </Grid>

            <Grid item container direction="row" className={classNames(c.grid, c.couponCodeContainer)}>
              <Grid item xs>
                <TextField
                  className={c.textField}
                  disabled={performingAction}
                  fullWidth
                  label="Coupon Code (optional)"
                  onChange={this.handleFieldChange('couponCode')}
                  onKeyDown={this.handleCouponFieldKeyDown}
                  readOnly={performingAction}
                  type="text"
                  value={couponCode}
                  variant="filled"
                />
              </Grid>
              <Grid item className={c.applyCouponContainer}>
                <Button
                  aria-label="click to apply coupon code"
                  className={c.applyCoupon}
                  color="secondary"
                  disabled={
                    !couponCode ||
                    (couponCode && couponDetails && couponCode.toUpperCase() === couponDetails.id.toUpperCase()) ||
                    performingAction
                  }
                  onClick={this.handleApplyCouponClick}
                  variant="contained"
                >
                  Apply
                </Button>
              </Grid>
            </Grid>

            <CouponApplied plan={plan} coupon={couponDetails} />
          </>
        )}

        {serverError && (
          <Box my={2}>
            <FeedbackSnackbarContent
              className={c.feedback}
              variant="error"
              message={serverError}
              onClose={this.handleFeedbackClose}
            />
          </Box>
        )}

        {serverSuccess && sponsoring && (
          <>
            <Box my={2}>
              <FeedbackSnackbarContent
                className={c.feedback}
                variant="success"
                message={
                  <span>
                    Your Brilliant TV account has been created!{'  '}
                    <a href="https://www.brillianttv.com/login"> Access Your Account</a>
                  </span>
                }
              />
            </Box>
          </>
        )}

        {serverSuccess && alreadyRegistered && (
          <>
            <Box my={2}>
              <FeedbackSnackbarContent
                className={c.feedback}
                variant="success"
                message="Thank you, you have been successfully subscribed at BrilliantTV!"
              />
            </Box>
            <Box my={2}>
              <FeedbackSnackbarContent
                className={c.feedback}
                variant="warning"
                message={
                  <span>
                    It looks like you already have an account with BrilliantTV.
                    {resetPasswordUrl && (
                      <span>
                        {' '}
                        Would you like to{' '}
                        <Link href={resetPasswordUrl} className={c.resetPasswordLink} target="_blank">
                          reset your password
                        </Link>
                        ?
                      </span>
                    )}
                  </span>
                }
              />
            </Box>
          </>
        )}

        {!prePurchased && !sponsoring && (
          <Button
            className={c.register}
            color="secondary"
            disabled={!fullName || !emailAddress || !password || !passwordConfirmation || performingAction}
            fullWidth
            onClick={this.handleRegisterClick}
            size="large"
            variant="contained"
            aria-label="click to submit payment"
          >
            Submit Payment
          </Button>
        )}

        {prePurchasedStep1 && (
          <Button
            className={c.register}
            color="secondary"
            disabled={!emailAddress || !emailAddressConfirmation}
            fullWidth
            onClick={this.handleFindSubscription}
            size="large"
            variant="contained"
            aria-label="click to register"
          >
            Find my subscription
          </Button>
        )}
        {!serverSuccess && (prePurchasedStep2 || sponsoring) && (
          <Button
            className={c.register}
            color="secondary"
            disabled={
              !fullName || !emailAddress || !password || !passwordConfirmation || performingAction || alreadyRegistered
            }
            fullWidth
            onClick={this.handleRegisterClick}
            size="large"
            variant="contained"
            aria-label="click to register"
          >
            Register
          </Button>
        )}

        {!serverSuccess && (
          <Grid item className={c.grid}>
            <FormControlLabel
              disabled={performingAction}
              control={
                <Checkbox
                  checked={marketingOptIn}
                  value="marketingOptIn"
                  color="secondary"
                  onChange={this.handleFieldCheck('marketingOptIn')}
                />
              }
              label={
                <Typography color="textSecondary" variant="body2" align="left" className={c.marketingOptIn}>
                  I agree to receive newsletters and product updates from Brilliant TV
                </Typography>
              }
            />
          </Grid>
        )}
        {!prePurchased && !sponsoring && (
          <>
            <Box spacing={2}>
              <img src="/icons/payment/visa.png" className={c.paymentTypeIcon} alt="visa card" />
              <img src="/icons/payment/mastercard.png" className={c.paymentTypeIcon} alt="master card" />
              <img
                src="/icons/payment/american-express.png"
                className={c.paymentTypeIcon}
                alt="americal express card"
              />
              <img src="/icons/payment/discover.png" className={c.paymentTypeIcon} alt="discover card" />
            </Box>

            <Typography color="textSecondary" component="small">
              <PadlockIcon className={c.padlock} height="12" />
              100% Safe &amp; Secure Payment
            </Typography>
          </>
        )}
      </form>
    );
  }
}

export default injectStripe(withStyles(styles, { withTheme: true })(CheckoutForm));
