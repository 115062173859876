const styles = (theme) => ({
  root: {
    '& small': {
      fontSize: 12,
    },
  },
  grid: {
    marginBottom: theme.spacing(2),
  },
  gridPayment: {
    marginTop: theme.spacing(2),
  },
  formHeader: {
    textAlign: 'left',
    marginBottom: theme.spacing(2),
  },
  marketingOptIn: {
    fontWeight: 600,
  },
  couponCodeContainer: {
    alignItems: 'flex-start',
  },
  applyCouponContainer: {
    alignSelf: 'flex-start',
    display: 'flex',
    marginTop: 22,
    marginLeft: theme.spacing(2),
  },
  feedback: {
    textAlign: 'left',
  },
  register: {
    backgroundColor: '#35BA0D',
    fontSize: '18px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    textTransform: 'none',
  },
  link: {
    fontWeight: 'bold',
  },
  errorLink: {
    fontWeight: 'bold',
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  resetPasswordLink: {
    color: '#1976d2',
  },
  login: {
    marginTop: theme.spacing(3),
  },
  padlock: {
    marginBottom: -2,
    marginRight: 4,
  },
  paymentTypeIcon: {
    width: 35,
    marginLeft: theme.spacing(1),
    '&:first-of-type': {
      marginLeft: 0,
    },
  },
});

export default styles;
