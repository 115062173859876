import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { loadRemoteConfig } from './config/environment';
import * as serviceWorker from './serviceWorker';

window.location.href = "https://www.brillianttv.com/auth/signup/basic?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYmFzaWMiLCJpYXQiOjE2MTk1NDUwNjN9.rRwcRotQkmY_YTZoVfpdgmtKw2HmAh_Fy_9JqJ-viF0"

loadRemoteConfig(window.location.pathname).finally(() => {
  ReactDOM.render(<App />, document.querySelector('#root'));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
