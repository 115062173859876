// @flow

import './App.css';
import 'paymentfont/css/paymentfont.css';

import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { teal } from '@material-ui/core/colors';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import routes from './Routes';

const theme = createMuiTheme({
  palette: {
    // primary: blue,
    primary: {
      main: '#0464B0',
    },
    secondary: {
      main: teal[500],
    },
    text: {
      primary: '#31394F',
    },
  },
  typography: {
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 13,
    fontWeightLight: 500,
    fontWeightRegular: 600,
    fontWeightMedium: 700,
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route component={() => { 
                window.location.href = 'https://www.brillianttv.com/auth/signup/basic?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYmFzaWMiLCJpYXQiOjE2MTk1NDUwNjN9.rRwcRotQkmY_YTZoVfpdgmtKw2HmAh_Fy_9JqJ-viF0'; 
                return null;
            }} />
          </Switch>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
